




import coreApiClient from "@/services/apis/coreApiClient";
import printService from "@/services/printService";
import downloadFile from "@/utils/downloadFile";
import DataContainer from "@common/modules/vuetifyx/common/DataContainer";
import printJs from "print-js";
import Vue from "vue";
export default Vue.extend({
  props: ["pretreatment"],
  data() {
    const self2 = this;
    return {
      setting: null,
      tableOptions: {
        attrs: {
          "item-key": "_id",
          "fixed-header": false,
        },
        content: {
          name: this.pretreatment ? "Pretreatment" : "Non-Pretreatment",
          displayFields: {
            productVariant: {
              text: "Name",
              options: {
                subProp: "productVariant.name",
                label: true,
              },
            },
            size: {
              text: "Size",
              options: {
                label: true,
                preTransform(_, item) {
                  return item.productVariant?.propertyValues?.size || item.productVariant?.propertyValues?.Size;
                },
              },
            },
            color: {
              text: "Color",
              options: {
                label: true,
                preTransform(_, item) {
                  return item.productVariant?.propertyValues?.color || item.productVariant?.propertyValues?.Color;
                },
              },
            },
            inProgressQuantity: {
              text: "In Progress Quantity",
            },
            quantity: {
              text: "Quantity",
            },
            total: {
              text: "Total",
              options: {
                preTransform(_, item) {
                  return (item.inProgressQuantity || 0) + (item.quantity || 0);
                },
              },
            },
            action: {},
          },
          topActionButtons: {
            print: {
              attrs: {
                color: "primary",
                dark: false,
              },
              content: {
                text: "Print Items",
                icon: "mdi-table",
              },
              on: {
                async xClick({ self }) {
                  const dataTable = self.context();
                  const data = dataTable.items.value;
                  printJs({
                    header: self2.pretreatment ? "Pretreatment" : "Non-Pretreatment",
                    printable: data
                      .map((item) => {
                        return {
                          productVariantName: item.productVariant.name,
                          size: item.productVariant.propertyValues.size || item.productVariant.propertyValues.Size,
                          color: item.productVariant.propertyValues.color || item.productVariant.propertyValues.Color,
                          inProgressQuantity: item.inProgressQuantity,
                          quantity: item.quantity,
                          total: (item.inProgressQuantity || 0) + (item.quantity || 0),
                        };
                      })
                      .concat([
                        {
                          productVariantName: "Total",
                          size: "",
                          color: "",
                          inProgressQuantity: data.reduce((acc, item) => acc + (item.inProgressQuantity || 0), 0),
                          quantity: data.reduce((acc, item) => acc + (item.quantity || 0), 0),
                          total: data.reduce(
                            (acc, item) => acc + (item.inProgressQuantity || 0) + (item.quantity || 0),
                            0
                          ),
                        },
                      ]),
                    type: "json",
                    properties: [
                      { field: "productVariantName", displayName: "Name" },
                      { field: "size", displayName: "Size" },
                      { field: "color", displayName: "Color" },
                      {
                        field: "inProgressQuantity",
                        displayName: "In Progress Quantity",
                      },
                      { field: "quantity", displayName: "Quantity" },
                      { field: "total", displayName: "Total" },
                    ],
                  });
                },
              },
            },
          },
          template: {
            itemActionButtons: {
              print: {
                content: {
                  icon: "mdi-printer",
                },
                on: {
                  async xClick({ self }) {
                    const { item, dataTable } = self.context();
                    self.loading.value = true;
                    await dataTable.execute(
                      async () => {
                        const result = await coreApiClient.callRaw("orderFulfillment", "printBarcode", {
                          productVariantId: item.productVariant._id,
                          pretreatment: self2.pretreatment,
                        });
                        const setting = self2.setting;
                        if (result) {
                          const printerItem = setting.printerItems.find(({ tags }) => {
                            return tags.includes("barCode");
                          });
                          await printService.printPdf(printerItem.name, result.printUrl, {
                            size: { width: 69.9, height: 31.8 },
                            units: "mm",
                            orientation: "portrait",
                            margins: {
                              top: "1",
                              right: "1",
                              bottom: "0",
                              left: "1",
                            },
                          });
                          dataTable.refresh();
                        }
                      },
                      undefined,
                      {
                        disableLoading: true,
                        willRefresh: false,
                      }
                    );

                    self.loading.value = false;
                  },
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Print Barcode",
                    },
                  },
                },
              },
              advancedPrint: {
                content: {
                  icon: "mdi-cloud-print",
                },
                target: {
                  tooltip: {
                    content: {
                      text: "Advanced Print Barcode",
                    },
                  },
                  dialog: {
                    handlers: {
                      initialize() {
                        this.options.content.buttons.save.states.enabled = new DataContainer(false);
                      },
                    },
                    attrs: {
                      width: "800px",
                    },
                    content: {
                      toolbar: {
                        title: "Advanced Print Barcode",
                        subTitle(dialog) {
                          const btn = dialog.context();
                          const { item } = btn.context();
                          return item.productVariant?.name;
                        },
                      },
                      content: {
                        type: "XForm",
                        makeAttrs(attrs, dialog) {
                          attrs.xModel = dialog.options.content.buttons.save.states.enabled;
                          attrs.xContext = dialog;
                          attrs.xData = new DataContainer({});
                          return attrs;
                        },
                        attrs: {
                          xOptions: {
                            content: {
                              sections: {
                                default: {
                                  fields: {
                                    orderIds: {
                                      type: "textMultiple",
                                      attrs: {
                                        label: "Order IDs",
                                      },
                                    },
                                    includeProcessingItems: {
                                      type: "boolean",
                                      attrs: {
                                        label: "Include Processing Items",
                                      },
                                      ext: {
                                        defaultValue: true,
                                      },
                                    },
                                    downloadInsteadOfPrint: {
                                      type: "boolean",
                                      attrs: {
                                        label: "Download Instead of Print",
                                      },
                                    },
                                  },
                                },
                              },
                            },
                          },
                        },
                      },
                      buttons: {
                        save: {
                          content: {
                            text: "Submit",
                          },
                          states: {},
                          on: {
                            async xClick({ self }) {
                              const dialog = self.context();
                              const btn = dialog.context();
                              const { dataTable, item } = btn.context();
                              const form = dialog.contentContainer.value;
                              const data = form.getData();
                              self.loading.value = true;
                              await dataTable.execute(
                                async () => {
                                  const result = await coreApiClient.callRaw("orderFulfillment", "printBarcode", {
                                    productVariantId: item.productVariant._id,
                                    pretreatment: self2.pretreatment,
                                    includeProcessingItems: data.includeProcessingItems,
                                    orderIds: data.orderIds?.join(","),
                                  });
                                  const setting = self2.setting;
                                  if (result) {
                                    if (data.downloadInsteadOfPrint) {
                                      downloadFile(result.printUrl);
                                      return;
                                    }
                                    const printerItem = setting.printerItems.find(({ tags }) => {
                                      return tags.includes("barCode");
                                    });
                                    await printService.printPdf(printerItem.name, result.printUrl, {
                                      size: { width: 69.9, height: 31.8 },
                                      units: "mm",
                                      orientation: "portrait",
                                      margins: {
                                        top: "1",
                                        right: "1",
                                        bottom: "0",
                                        left: "1",
                                      },
                                    });
                                    dataTable.refresh();
                                    dialog.hide();
                                  }
                                },
                                undefined,
                                {
                                  disableLoading: true,
                                  willRefresh: false,
                                }
                              );
                              self.loading.value = false;
                            },
                          },
                        },
                      },
                    },
                  },
                },
              },
            },
          },
        },
        ext: {
          dataProvider: {
            async findAll(options) {
              const response = await coreApiClient.call("orderFulfillment", "preparePrintBarcode", {
                pretreatment: self2.pretreatment,
              });
              let items = response.items || [];
              if (options.search) {
                items = items.filter((item) => {
                  return item.productVariant.name.toLowerCase().includes(options.search.toLowerCase());
                });
              }
              return [items, items.length];
            },
          },
        },
      },
    };
  },
  async created() {
    this.setting = await coreApiClient.call("setting", "get");
  },
});
